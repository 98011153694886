import LoadingScreen from 'components/LoadingScreen';
import { OnboardingStepCheck } from 'components/OnboardingStepCheck';
import { useCurrentPharmacy, useCurrentRole, useCurrentUser } from 'hooks/slices';
import { useGetResellerWordingType } from 'hooks/useGetResellerWordingType';
import { useShouldAcceptCgu } from 'hooks/useShouldAcceptCgu';
import LogoOnlyLayout from 'layouts/LogoOnlyLayout';
import OnboarderLayout from 'layouts/OnboarderLayout';
import OnboardingLayout from 'layouts/OnboardingLayout';
import MainLayout from 'layouts/dashboard';
import { isNil } from 'lodash';
import AdminSelloutPanelistGeneratorPage from 'pages/admin/SelloutPanelistGenerator/AdminSelloutPanelistGeneratorPage';
import { AcceptCguPage } from 'pages/shared/AcceptCguPage';
import FromIdentityProvider from 'pages/shared/Login/FromIdentityProvider';
import FromToken from 'pages/shared/Login/FromToken';
import Invitation from 'pages/shared/Login/Invitation/Invitation';
import VerifyCode from 'pages/shared/Login/VerifyCode/VerifyCode';
import VerifyEmail from 'pages/shared/Login/VerifyEmail/VerifyEmail';
import VerifyPhoneNumber from 'pages/shared/Login/VerifyPhoneNumber/VerifyPhoneNumber';
import { ElementType, ReactNode, Suspense, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, RouteObject, useLocation, useRoutes, useSearchParams } from 'react-router-dom';
import { selectUserToken } from 'redux/slices/auth';
import { isPharmacyRole } from 'utils/rolesUtils';
import { lazyRetry } from 'utils/routesUtils';
import RootNavigate from './RootNavigate';
import {
  AdminPaths,
  GroupPaths,
  LabPaths,
  LoginPaths,
  OnboarderPaths,
  OnboardingPaths,
  PharmacyPaths,
  SharedPaths,
} from './paths';
import {
  canAccessAdminPages,
  canAccessGroupPages,
  canAccessLabPages,
  canAccessOnboarderPages,
  canAccessOnboardingPages,
  canAccessPharmacyPages,
} from './routeUtils';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/pharmacy')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const authToken = useSelector(selectUserToken);
  const [searchParams] = useSearchParams();
  const labToken = searchParams.get('labToken');
  const { pathname } = useLocation();
  const { isLoading: isResellerWordingTypeLoading } = useGetResellerWordingType();

  const currentUser = useCurrentUser();
  const currentRole = useCurrentRole();
  const pharmacy = useCurrentPharmacy();
  const shouldAcceptCguState = useShouldAcceptCgu();

  const displayRouteOnlyIf = (predicate: boolean, component: ReactNode) =>
    predicate ? (
      component
    ) : (
      <Navigate
        to={`/?destination_path=${encodeURIComponent(
          window.location.pathname + window.location.search
        )}`}
        replace
      />
    );

  const withSectionOnboardingStepCheck = useCallback(
    (routeObjects: RouteObject[] | undefined): RouteObject[] | undefined =>
      routeObjects?.map((routeObject) => ({
        ...routeObject,
        element: (
          <OnboardingStepCheck
            component={routeObject.element}
            pathname={pathname}
            pharmacy={pharmacy}
            role={currentRole}
            user={currentUser}
          />
        ),
      })),
    [pathname, pharmacy, currentRole, currentUser]
  );

  const shouldRenderGeneralLoader = !!authToken && !currentUser;

  const waitForContextLoadingAnd = (element: ReactNode) => {
    if (
      shouldRenderGeneralLoader ||
      shouldAcceptCguState.isLoading ||
      isResellerWordingTypeLoading
    ) {
      return <LoadingScreen />;
    } else if (shouldAcceptCguState.shouldAcceptCgu) {
      return <AcceptCguPage />;
    }

    return element;
  };

  return useRoutes([
    {
      path: '/',
      element: waitForContextLoadingAnd(<RootNavigate />),
    },
    {
      path: '/login',
      element: <LoginPage shouldRenderGeneralLoader={shouldRenderGeneralLoader} />,
      children: [
        { element: <Navigate to={LoginPaths.PhoneNumber} replace />, index: true },
        { path: LoginPaths.PhoneNumber, element: <VerifyPhoneNumber /> },
        { path: LoginPaths.Code, element: <VerifyCode /> },
        { path: LoginPaths.Email, element: <VerifyEmail /> },
        { path: LoginPaths.Invitation, element: <Invitation /> },
        { path: LoginPaths.PasswordReset, element: <SendResetPasswordEmail /> },
        { path: LoginPaths.PasswordEdit, element: <EditPassword /> },
        { path: LoginPaths.FromToken, element: <FromToken /> },
        { path: LoginPaths.FromIdentityProvider, element: <FromIdentityProvider /> },
      ],
    },
    {
      path: '/onboarding',
      element: waitForContextLoadingAnd(
        displayRouteOnlyIf(canAccessOnboardingPages(currentRole), <OnboardingLayout />)
      ),
      children: withSectionOnboardingStepCheck([
        { element: <Navigate to={OnboardingPaths.PharmacistOrLab} replace />, index: true },
        { path: OnboardingPaths.PharmacistOrLab, element: <PharmacistOrLabPage /> },
        { path: OnboardingPaths.LabUnavailable, element: <LabUnavailablePage /> },
        { path: OnboardingPaths.CreateProfile, element: <CreateProfilePage /> },
        { path: OnboardingPaths.SearchPharmacy, element: <SearchPharmacyPage /> },
        { path: OnboardingPaths.CreatePharmacy, element: <CreatePharmacyPage /> },
        { path: OnboardingPaths.JoinPharmacy, element: <JoinPharmacyPage /> },
        { path: OnboardingPaths.VerifyEmail, element: <VerifyEmailPage /> },
        { path: OnboardingPaths.VerifyAccount, element: <VerifyAccountPage /> },
      ]),
    },
    {
      path: '/onboarder',
      element: waitForContextLoadingAnd(
        displayRouteOnlyIf(canAccessOnboarderPages(currentUser), <OnboarderLayout />)
      ),
      children: [
        { element: <Navigate to={OnboarderPaths.Pharmacies} replace />, index: true },
        { path: OnboarderPaths.Pharmacies, element: <OnboarderPharmaciesPage /> },
        { path: OnboarderPaths.PharmacyEdit, element: <OnboarderPharmacyEditPage /> },
      ],
    },
    {
      path: '/admin',
      element: waitForContextLoadingAnd(
        displayRouteOnlyIf(canAccessAdminPages(currentUser), <MainLayout />)
      ),
      children: [
        { element: <Navigate to="/admin/home" replace />, index: true },
        { path: AdminPaths.Home, element: <AdminHomePage /> },
        { path: AdminPaths.PharmaciesMerge, element: <AdminPharmaciesMergePage /> },
        { path: AdminPaths.Impersonate, element: <AdminImpersonatePage /> },
        { path: AdminPaths.Datamatrix, element: <AdminDatamatrixGeneratorPage /> },
        {
          path: AdminPaths.SelloutPanelistGenerator,
          element: <AdminSelloutPanelistGeneratorPage />,
        },
        { path: AdminPaths.IngestionsHub, element: <AdminIngestionsHubPage /> },
        { path: AdminPaths.NewIngestion, element: <AdminIngestionHubNewPage /> },
        { path: AdminPaths.InboundMails, element: <AdminInboundMailsPage /> },
        { path: AdminPaths.Orders, element: <AdminOrdersPage /> },
        { path: AdminPaths.Order, element: <AdminOrderPage /> },
        { path: AdminPaths.Pharmacies, element: <AdminPharmaciesPage /> },
        { path: AdminPaths.PharmacyNew, element: <AdminPharmacyNewPage /> },
        { path: AdminPaths.Pharmacy, element: <AdminPharmacyPage /> },
        { path: AdminPaths.OrderEdit, element: <AdminOrderEditPage /> },
        { path: AdminPaths.OrderNew, element: <AdminOrderNewPage /> },
        { path: AdminPaths.LabTrades, element: <AdminLabTradesPage /> },
        { path: AdminPaths.ExpiredProductsRequests, element: <AdminExpiredProductsRequestsPage /> },
        { path: AdminPaths.ExpiredProductsRequest, element: <AdminExpiredProductsRequestPage /> },
        { path: AdminPaths.Claims, element: <AdminClaimsPage /> },
        { path: AdminPaths.Claim, element: <AdminClaimPage /> },
        { path: AdminPaths.Trades, element: <AdminTradesPage /> },
        { path: AdminPaths.Trade, element: <AdminTradePage /> },
        { path: AdminPaths.TradesInstances, element: <AdminTradesInstancesPage /> },
        { path: AdminPaths.TradeInstance, element: <AdminTradeInstancePage /> },
        { path: AdminPaths.Groups, element: <AdminGroupsPage /> },
        { path: AdminPaths.GroupNew, element: <AdminGroupNewPage /> },
        { path: AdminPaths.Group, element: <AdminGroupPage /> },
        { path: AdminPaths.Labs, element: <AdminLabsPage /> },
        { path: AdminPaths.LabNew, element: <AdminLabNewPage /> },
        { path: AdminPaths.Lab, element: <AdminLabPage /> },
        { path: AdminPaths.MarketingTools, element: <AdminMarketingToolsPage /> },
        { path: AdminPaths.MarketingToolNew, element: <AdminMarketingToolNewPage /> },
        { path: AdminPaths.MarketingTool, element: <AdminMarketingToolPage /> },
        { path: AdminPaths.NewsfeedPosts, element: <AdminNewsfeedPostsPage /> },
        { path: AdminPaths.NewsfeedPost, element: <AdminNewsfeedPostPage /> },
        { path: AdminPaths.NewsfeedPostNew, element: <AdminNewsfeedPostNewPage /> },
        { path: AdminPaths.NewsfeedPostEdit, element: <AdminNewsfeedPostEditPage /> },
        { path: AdminPaths.ClaimRelaunchPlans, element: <AdminClaimRelaunchPlansPage /> },
        { path: AdminPaths.ClaimRelaunchPlanNew, element: <AdminClaimRelaunchPlanNewPage /> },
        { path: AdminPaths.ClaimRelaunchPlanEdit, element: <AdminClaimRelaunchPlanEditPage /> },
        { path: AdminPaths.Users, element: <AdminUsersPage /> },
        { path: AdminPaths.User, element: <AdminUserPage /> },
        { path: AdminPaths.UserNew, element: <AdminUserNewPage /> },
        { path: AdminPaths.UserEdit, element: <AdminUserEditPage /> },
        { path: AdminPaths.GroupsLabs, element: <AdminGroupsLabsPage /> },
        { path: AdminPaths.CheckNeedingPharmacies, element: <AdminCheckNeedingPharmaciesPage /> },
        { path: AdminPaths.ReferentialPharmacies, element: <AdminReferentialPharmaciesPage /> },
      ],
    },
    {
      path: '/pharmacy',
      element: waitForContextLoadingAnd(
        displayRouteOnlyIf(canAccessPharmacyPages(currentRole), <MainLayout />)
      ),
      children: [
        {
          element: <Navigate to={PharmacyPaths.Trades} replace />,
          index: true,
        },
        { path: PharmacyPaths.Claims, element: <PharmacyClaims /> },
        { path: PharmacyPaths.ClaimNewPage, element: <PharmacyClaimNewPage /> },
        { path: PharmacyPaths.Claim, element: <PharmacyClaim /> },
        { path: PharmacyPaths.Group, element: <PharmacyGroupPage /> },
        { path: PharmacyPaths.Trades, element: <PharmacyTradesPage /> },
        { path: PharmacyPaths.Trade, element: <PharmacyTradePage /> },
        { path: PharmacyPaths.TradeNew, element: <PharmacyTradeNewPage /> },
        { path: PharmacyPaths.Stripe, element: <PharmacyStripePage /> },
        { path: PharmacyPaths.StripeBillingForm, element: <PharmacyStripeBillingFormPage /> },
        { path: PharmacyPaths.LgoSetting, element: <PharmacyPanelistConnectionPage /> },
        {
          path: PharmacyPaths.LabsDiscussions,
          element: pharmacy?.is_lab_chat_enabled ? (
            <PharmacyLabsDiscussionsPage />
          ) : (
            <Navigate to={SharedPaths.NotFound} />
          ),
          children: [{ path: ':id', element: <PharmacyLabsDiscussionsPage /> }],
        },
        {
          path: PharmacyPaths.Orders,
          element: pharmacy?.is_order_enabled ? (
            <OrdersPage />
          ) : (
            <Navigate to={SharedPaths.NotFound} />
          ),
          children: [
            { path: ':id', element: <OrdersPage /> },
            { path: 'new', element: <OrdersPage /> },
            { path: 'restocking', element: <OrdersPage /> },
          ],
        },
        { path: PharmacyPaths.LabsPharmacies, element: <PharmacyLabsPage /> },
        { path: PharmacyPaths.LabsPharmacy, element: <PharmacyLabsPharmacyPage /> },
        { path: PharmacyPaths.ExpiredProductsRequest, element: <PharmacyExpiredProductsRequest /> },
        { path: PharmacyPaths.PharmacyEdit, element: <PharmacyPharmacyEditPage /> },
        { path: PharmacyPaths.Compensations, element: <PharmacyCompensationsPage /> },
      ],
    },
    {
      path: '/user',
      element: waitForContextLoadingAnd(displayRouteOnlyIf(!isNil(currentRole), <MainLayout />)),
      children: [
        { element: <Navigate to={SharedPaths.EditCurrentUser} replace />, index: true },
        { path: SharedPaths.EditCurrentUser, element: <ProfilePage /> },
        {
          path: PharmacyPaths.EditCurrentPharmacy,
          element: displayRouteOnlyIf(
            !!currentRole && isPharmacyRole(currentRole),
            <PharmacyPage />
          ),
        },
      ],
    },
    {
      path: '/group',
      element: waitForContextLoadingAnd(
        displayRouteOnlyIf(canAccessGroupPages(currentRole), <MainLayout />)
      ),
      children: [
        { element: <Navigate to={GroupPaths.Dashboard} replace />, index: true },
        { path: GroupPaths.Dashboard, element: <GroupDashboardPage /> },
        { path: GroupPaths.NewsfeedPosts, element: <GroupNewsfeedPostsPage /> },
        { path: GroupPaths.NewsfeedPost, element: <GroupNewsfeedPostPage /> },
        { path: GroupPaths.NewsfeedPostEdit, element: <GroupNewsfeedPostEditPage /> },
        { path: GroupPaths.NewsfeedPostNew, element: <GroupNewsfeedPostNewPage /> },
        { path: GroupPaths.Pharmacies, element: <GroupPharmaciesPage /> },
        { path: GroupPaths.PharmacyEdit, element: <GroupPharmacyEditPage /> },
        { path: GroupPaths.PharmacyLists, element: <SharedPharmacyListsPage /> },
        { path: GroupPaths.PharmacyListNew, element: <SharedPharmacyListIngestionPage /> },
        { path: GroupPaths.PharmacyList, element: <SharedPharmacyListPage /> },
        { path: GroupPaths.Claims, element: <GroupClaimsPage /> },
        { path: GroupPaths.Claim, element: <GroupClaimPage /> },
        { path: GroupPaths.GroupsLabs, element: <GroupGroupsLabsPage /> },
        { path: GroupPaths.GroupsLabsEdit, element: <GroupGroupsLabsEditPage /> },
        { path: GroupPaths.Trades, element: <GroupTradesPage /> },
        { path: GroupPaths.Trade, element: <GroupTradePage /> },
        { path: GroupPaths.TradeNew, element: <GroupTradeNewPage /> },
        { path: GroupPaths.TradeEdit, element: <GroupTradeEditPage /> },
        { path: GroupPaths.GroupEdit, element: <GroupEditPage /> },
      ],
    },
    {
      path: '/',
      element: waitForContextLoadingAnd(<MainLayout />),
      children: [
        { path: LabPaths.SecuredClaimOld, element: <OldSecuredLabClaimPage /> },
        { path: LabPaths.SecuredLabClaim, element: <SecuredLabClaimPage /> },
      ],
    },
    {
      path: '/lab',
      element: waitForContextLoadingAnd(
        displayRouteOnlyIf(canAccessLabPages(currentRole), <MainLayout />)
      ),
      children: [
        { element: <Navigate to={LabPaths.TradesDashboard} replace />, index: true },
        { path: LabPaths.TradesDashboard, element: <LabTradesDashboardPage /> },
        { path: LabPaths.Trades, element: <LabTradeInstancesPage /> },
        { path: LabPaths.MasterTrades, element: <LabMasterTradesPage /> },
        { path: LabPaths.MasterTradeNew, element: <LabMasterTradeNewPage /> },
        { path: LabPaths.MasterTrade, element: <LabMasterTradePage /> },
        { path: LabPaths.ClaimsList, element: <LabClaimsPage /> },
        { path: LabPaths.Claim, element: <LabClaimPage /> },
        { path: LabPaths.GroupsLabs, element: <LabGroupsLabsPage /> },
        { path: LabPaths.LabsPharmacies, element: <LabLabsPharmaciesPage /> },
        { path: LabPaths.LabsPharmacy, element: <LabsPharmacyPage /> },
        { path: LabPaths.GuideItems, element: <LabGuideItemsPage /> },
        { path: LabPaths.GuideItemNew, element: <LabGuideItemNewPage /> },
        { path: LabPaths.GuideItemEdit, element: <LabGuideItemEditPage /> },
        { path: LabPaths.RegionNew, element: <LabRegionNewPage /> },
        { path: LabPaths.RegionEdit, element: <LabRegionEditPage /> },
        { path: LabPaths.GroupsLabEdit, element: <LabGroupsLabEditPage /> },
        { path: LabPaths.ExpiredProductsRequestsList, element: <LabExpiredProductsRequestsPage /> },
        { path: LabPaths.ClaimsDashboard, element: <LabClaimsDashboardPage /> },
        { path: LabPaths.EditLab, element: <LabEditPage /> },
        {
          path: LabPaths.ExpiredProductsRequestsDashboard,
          element: <LabExpiredProductsRequestsDashboardPage />,
        },
        { path: LabPaths.ExpiredProductsRequest, element: <LabExpiredProductsRequestPage /> },
        { path: LabPaths.TopicNew, element: <LabTopicNewPage /> },
        { path: LabPaths.TopicEdit, element: <LabTopicEditPage /> },
        { path: LabPaths.PharmacyLists, element: <SharedPharmacyListsPage /> },
        { path: LabPaths.PharmacyListNew, element: <SharedPharmacyListIngestionPage /> },
        { path: LabPaths.PharmacyList, element: <SharedPharmacyListPage /> },
        { path: LabPaths.OrdersList, element: <LabOrdersListPage /> },
        { path: LabPaths.Order, element: <LabOrderPage /> },
        { path: LabPaths.ProductsList, element: <LabProductsListPage /> },
      ],
    },
    {
      // Only with lab Token
      path: '/securedaccess',
      element: waitForContextLoadingAnd(
        authToken === null && labToken !== null ? <MainLayout /> : <Navigate to={LabPaths.Trades} />
      ),
      children: [
        { element: <Navigate to={LabPaths.SecuredAccessTrades} replace />, index: true },
        { path: LabPaths.SecuredAccessDashboard, element: <LabTradesDashboardPage /> },
        { path: LabPaths.SecuredAccessTrades, element: <LabTradeInstancesPage /> },
      ],
    },
    {
      path: '/',
      element: waitForContextLoadingAnd(displayRouteOnlyIf(!isNil(currentRole), <MainLayout />)),
      children: [
        { path: SharedPaths.Unauthorized, element: <UnauthorizedPage /> },
        { path: SharedPaths.NotFound, element: <NotFound /> },
      ],
    },
    {
      path: '*',
      element: waitForContextLoadingAnd(<LogoOnlyLayout />),
      children: [{ path: '*', element: <Navigate to={SharedPaths.NotFound} replace /> }],
    },
    { path: SharedPaths.InvalidToken, element: waitForContextLoadingAnd(<InvalidToken />) },
    { path: SharedPaths.StripeState, element: waitForContextLoadingAnd(<StripeStatePage />) },
    {
      path: '*',
      element: waitForContextLoadingAnd(<Navigate to={SharedPaths.NotFound} replace />),
    },
  ]);
}
const LoginPage = Loadable(lazyRetry(() => import('pages/shared/Login/LoginPage')));
const LabTradesDashboardPage = Loadable(
  lazyRetry(() => import('pages/lab/Trades/Dashboard/LabTradesDashboardPage'))
);
const LabTradeInstancesPage = Loadable(
  lazyRetry(
    () => import('pages/lab/Trades/LabTradeInstances/LabTradeInstancesPage/LabTradeInstancesPage')
  )
);
const LabMasterTradesPage = Loadable(
  lazyRetry(
    () => import('pages/lab/Trades/LabMasterTrades/LabMasterTradesPage/LabMasterTradesPage')
  )
);
const LabMasterTradeNewPage = Loadable(
  lazyRetry(
    () => import('pages/lab/Trades/LabMasterTrades/LabMasterTradeNewPage/MasterTradeNewPage')
  )
);
const LabMasterTradePage = Loadable(
  lazyRetry(() => import('pages/lab/Trades/LabMasterTrades/LabMasterTradePage/LabMasterTradePage'))
);
const LabGroupsLabsPage = Loadable(
  lazyRetry(() => import('pages/lab/GroupsLabs/LabGroupsLabsPage/LabGroupsLabsPage'))
);
const LabClaimsPage = Loadable(
  lazyRetry(() => import('pages/lab/Claims/LabClaimsPage/LabClaimsPage'))
);
const LabClaimPage = Loadable(lazyRetry(() => import('pages/lab/Claims/LabClaimPage')));
const SecuredLabClaimPage = Loadable(
  lazyRetry(() => import('pages/secured/Claims/SecuredLabClaimPage'))
);
const OldSecuredLabClaimPage = Loadable(
  lazyRetry(() => import('pages/secured/Claims/OldSecuredLabClaimPage'))
);
const LabLabsPharmaciesPage = Loadable(
  lazyRetry(() => import('pages/lab/LabsPharmacies/LabLabsPharmaciesPage/LabLabsPharmaciesPage'))
);
const LabsPharmacyPage = Loadable(
  lazyRetry(() => import('pages/lab/LabsPharmacies/LabLabsPharmacyPage/LabLabsPharmacyPage'))
);
const LabGuideItemsPage = Loadable(
  lazyRetry(() => import('pages/lab/GuideItems/LabGuideItemsPage/LabGuideItemsPage'))
);
const LabGuideItemNewPage = Loadable(
  lazyRetry(() => import('pages/lab/GuideItems/LabGuideItemNewPage'))
);
const LabGuideItemEditPage = Loadable(
  lazyRetry(() => import('pages/lab/GuideItems/LabGuideItemEditPage'))
);
const LabRegionNewPage = Loadable(lazyRetry(() => import('pages/lab/Regions/LabRegionNewPage')));
const LabRegionEditPage = Loadable(lazyRetry(() => import('pages/lab/Regions/LabRegionEditPage')));
const LabGroupsLabEditPage = Loadable(
  lazyRetry(() => import('pages/lab/GroupsLabs/LabGroupsLabEditPage'))
);
const LabExpiredProductsRequestsPage = Loadable(
  lazyRetry(
    () =>
      import(
        'pages/lab/ExpiredProductsRequests/LabExpiredProductsRequestsPage/LabExpiredProductsRequestsPage'
      )
  )
);
const LabClaimsDashboardPage = Loadable(
  lazyRetry(() => import('pages/lab/Claims/LabClaimsDashboardPage/LabClaimsDashboardPage'))
);
const LabEditPage = Loadable(lazyRetry(() => import('pages/lab/Edit/LabEditPage')));
const LabExpiredProductsRequestsDashboardPage = Loadable(
  lazyRetry(
    () => import('pages/lab/ExpiredProductsRequests/LabExpiredProductsRequestsDashboardPage')
  )
);
const LabExpiredProductsRequestPage = Loadable(
  lazyRetry(() => import('pages/lab/ExpiredProductsRequests/LabExpiredProductsRequestPage'))
);
const LabTopicNewPage = Loadable(lazyRetry(() => import('pages/lab/Topics/LabTopicNewPage')));
const LabTopicEditPage = Loadable(lazyRetry(() => import('pages/lab/Topics/LabTopicEditPage')));
const LabOrdersListPage = Loadable(
  lazyRetry(() => import('pages/lab/Orders/LabOrdersPage/LabOrdersPage'))
);
const LabOrderPage = Loadable(
  lazyRetry(() => import('pages/lab/Orders/LabOrderPage/LabOrderPage'))
);
const LabProductsListPage = Loadable(lazyRetry(() => import('pages/lab/Orders/Products')));

const PharmacyTradesPage = Loadable(
  lazyRetry(() => import('pages/pharmacy/Trades/PharmacyTradesPage/PharmacyTradesPage'))
);
const PharmacyTradePage = Loadable(
  lazyRetry(() => import('pages/pharmacy/Trades/PharmacyTradePage/PharmacyTradePage'))
);
const PharmacyTradeNewPage = Loadable(
  lazyRetry(() => import('pages/pharmacy/Trades/PharmacyTradeNewPage/PharmacyTradeNewPage'))
);
const PharmacyLabsDiscussionsPage = Loadable(
  lazyRetry(
    () =>
      import('pages/pharmacy/SalesRepDiscussions/SalesRepDiscussionsPage/SalesRepDiscussionsPage')
  )
);

const OrdersPage = Loadable(
  lazyRetry(() => import('pages/pharmacy/Orders/PharmacyOrdersPage/PharmacyOrdersPage'))
);
const ProfilePage = Loadable(lazyRetry(() => import('pages/shared/Profile/ProfilePage')));
const PharmacyPage = Loadable(lazyRetry(() => import('pages/shared/Profile/PharmacyPage')));
const PharmacyGroupPage = Loadable(
  lazyRetry(() => import('pages/pharmacy/Group/PharmacyGroupPage'))
);
const PharmacyCompensationsPage = Loadable(
  lazyRetry(
    () => import('pages/pharmacy/Compensations/PharmacyCompensationsPage/PharmacyCompensationsPage')
  )
);

const GroupDashboardPage = Loadable(
  lazyRetry(() => import('pages/group/Dashboard/GroupDashboardPage'))
);
const GroupNewsfeedPostsPage = Loadable(
  lazyRetry(() => import('pages/group/Newsfeed/GroupNewsfeedPostsPage/GroupNewsfeedPostsPage'))
);
const GroupNewsfeedPostPage = Loadable(
  lazyRetry(() => import('pages/group/Newsfeed/GroupNewsfeedPostPage/GroupNewsfeedPostPage'))
);
const GroupNewsfeedPostEditPage = Loadable(
  lazyRetry(() => import('pages/group/Newsfeed/GroupNewsfeedPostEditPage'))
);
const GroupNewsfeedPostNewPage = Loadable(
  lazyRetry(() => import('pages/group/Newsfeed/GroupNewsfeedPostNewPage'))
);

const GroupPharmaciesPage = Loadable(
  lazyRetry(() => import('pages/group/Pharmacies/GroupPharmaciesPage/GroupPharmaciesPage'))
);
const GroupPharmacyEditPage = Loadable(
  lazyRetry(() => import('pages/group/Pharmacies/GroupPharmacyEditPage'))
);
const GroupTradesPage = Loadable(
  lazyRetry(() => import('pages/group/Trades/GroupTradesPage/GroupTradesPage'))
);
const GroupTradePage = Loadable(
  lazyRetry(() => import('pages/group/Trades/GroupTradePage/GroupTradePage'))
);
const GroupClaimsPage = Loadable(
  lazyRetry(() => import('pages/group/Claims/GroupClaimsPage/GroupClaimsPage'))
);
const GroupTradeNewPage = Loadable(lazyRetry(() => import('pages/group/Trades/GroupTradeNewPage')));
const GroupTradeEditPage = Loadable(
  lazyRetry(() => import('pages/group/Trades/GroupTradeEditPage'))
);
const GroupClaimPage = Loadable(
  lazyRetry(() => import('pages/group/Claims/GroupClaimPage/GroupClaimPage'))
);
const GroupGroupsLabsPage = Loadable(
  lazyRetry(() => import('pages/group/GroupsLabs/GroupGroupsLabsPage/GroupGroupsLabsPage'))
);
const GroupGroupsLabsEditPage = Loadable(
  lazyRetry(() => import('pages/group/GroupsLabs/GroupGroupsLabsEditPage'))
);
const GroupEditPage = Loadable(lazyRetry(() => import('pages/group/Edit/GroupEditPage')));
const PharmacyClaims = Loadable(
  lazyRetry(() => import('pages/pharmacy/Cases/PharmacyCasesPage/PharmacyCasesPage'))
);
const PharmacyClaimNewPage = Loadable(
  lazyRetry(() => import('pages/pharmacy/Cases/Claims/PharmacyClaimNewPage/PharmacyClaimNewPage'))
);
const PharmacyClaim = Loadable(
  lazyRetry(() => import('pages/pharmacy/Cases/Claims/PharmacyClaimPage/PharmacyClaimPage'))
);
const PharmacyExpiredProductsRequest = Loadable(
  lazyRetry(
    () =>
      import(
        'pages/pharmacy/Cases/ExpiredProductsRequests/PharmacyExpiredProductsRequestPage/PharmacyExpiredProductsRequestPage'
      )
  )
);
const PharmacyLabsPage = Loadable(
  lazyRetry(() => import('pages/pharmacy/Labs/PharmacyLabsPage/PharmacyLabsPage'))
);
const PharmacyLabsPharmacyPage = Loadable(
  lazyRetry(
    () => import('pages/pharmacy/LabsPharmacies/PharmacyLabsPharmacyPage/PharmacyLabsPharmacyPage')
  )
);
const PharmacyPharmacyEditPage = Loadable(
  lazyRetry(() => import('pages/pharmacy/Edit/PharmacyEditPage'))
);
const SharedPharmacyListPage = Loadable(
  lazyRetry(() => import('pages/shared/PharmacyLists/PharmacyListPage'))
);
const SharedPharmacyListIngestionPage = Loadable(
  lazyRetry(() => import('pages/shared/PharmacyLists/PharmacyListIngestionPage'))
);
const SharedPharmacyListsPage = Loadable(
  lazyRetry(() => import('pages/shared/PharmacyLists/PharmacyListsPage'))
);
const UnauthorizedPage = Loadable(lazyRetry(() => import('pages/shared/errors/Page403')));
const NotFound = Loadable(lazyRetry(() => import('pages/shared/errors/Page404')));
const InvalidToken = Loadable(lazyRetry(() => import('pages/shared/errors/Page498')));
const PharmacistOrLabPage = Loadable(
  lazyRetry(() => import('pages/shared/Onboarding/PharmacistOrLabPage'))
);
const LabUnavailablePage = Loadable(
  lazyRetry(() => import('pages/shared/Onboarding/LabUnavailablePage'))
);
const CreateProfilePage = Loadable(
  lazyRetry(() => import('pages/shared/Onboarding/CreateProfilePage'))
);
const SearchPharmacyPage = Loadable(
  lazyRetry(() => import('pages/shared/Onboarding/SearchPharmacyPage'))
);
const CreatePharmacyPage = Loadable(
  lazyRetry(() => import('pages/shared/Onboarding/CreatePharmacyPage'))
);
const JoinPharmacyPage = Loadable(
  lazyRetry(() => import('pages/shared/Onboarding/JoinPharmacyPage'))
);
const VerifyEmailPage = Loadable(
  lazyRetry(() => import('pages/shared/Onboarding/VerifyEmailPage'))
);
const VerifyAccountPage = Loadable(
  lazyRetry(() => import('pages/shared/Onboarding/VerifyAccountPage'))
);
const OnboarderPharmaciesPage = Loadable(
  lazyRetry(() => import('pages/onboarder/OnboarderPharmaciesPage/OnboarderPharmaciesPage'))
);
const OnboarderPharmacyEditPage = Loadable(
  lazyRetry(() => import('pages/onboarder/OnboarderPharmacyEditPage/OnboarderPharmacyEditPage'))
);
const AdminHomePage = Loadable(lazyRetry(() => import('pages/admin/Home/AdminHomePage')));
const AdminImpersonatePage = Loadable(
  lazyRetry(() => import('pages/admin/Impersonate/AdminImpersonatePage'))
);
const AdminInboundMailsPage = Loadable(
  lazyRetry(() => import('pages/admin/InboundMails/AdminInboundMailsPage/AdminInboundMailsPage'))
);
const AdminOrdersPage = Loadable(
  lazyRetry(() => import('pages/admin/Orders/AdminOrdersPage/AdminOrdersPage'))
);
const AdminOrderPage = Loadable(lazyRetry(() => import('pages/admin/Orders/AdminOrderPage')));
const AdminOrderEditPage = Loadable(
  lazyRetry(() => import('pages/admin/Orders/AdminOrderEditPage'))
);
const AdminOrderNewPage = Loadable(lazyRetry(() => import('pages/admin/Orders/AdminOrderNewPage')));
const AdminLabTradesPage = Loadable(
  lazyRetry(() => import('pages/admin/LabTrades/AdminLabTradesPage/AdminLabTradesPage'))
);
const AdminExpiredProductsRequestsPage = Loadable(
  lazyRetry(
    () =>
      import(
        'pages/admin/ExpiredProductsRequests/AdminExpiredProductsRequestsPage/AdminExpiredProductsRequestsPage'
      )
  )
);
const AdminExpiredProductsRequestPage = Loadable(
  lazyRetry(
    () =>
      import(
        'pages/admin/ExpiredProductsRequests/AdminExpiredProductsRequestPage/AdminExpiredProductsRequestPage'
      )
  )
);
const AdminClaimsPage = Loadable(
  lazyRetry(() => import('pages/admin/Claims/AdminClaimsPage/AdminClaimsPage'))
);
const AdminClaimPage = Loadable(
  lazyRetry(() => import('pages/admin/Claims/AdminClaimPage/AdminClaimPage'))
);
const AdminPharmaciesPage = Loadable(
  lazyRetry(() => import('pages/admin/Pharmacies/AdminPharmaciesPage/AdminPharmaciesPage'))
);
const AdminPharmacyNewPage = Loadable(
  lazyRetry(() => import('pages/admin/Pharmacies/AdminPharmacyNewPage'))
);
const AdminPharmacyPage = Loadable(
  lazyRetry(() => import('pages/admin/Pharmacies/AdminPharmacyPage/AdminPharmacyPage'))
);
const AdminGroupsPage = Loadable(
  lazyRetry(() => import('pages/admin/Groups/AdminGroupsPage/AdminGroupsPage'))
);
const AdminGroupNewPage = Loadable(
  lazyRetry(() => import('pages/admin/Groups/AdminGroupNewPage/AdminGroupNewPage'))
);
const AdminGroupPage = Loadable(
  lazyRetry(() => import('pages/admin/Groups/AdminGroupPage/AdminGroupPage'))
);
const AdminLabsPage = Loadable(
  lazyRetry(() => import('pages/admin/Labs/AdminLabsPage/AdminLabsPage'))
);
const AdminLabNewPage = Loadable(
  lazyRetry(() => import('pages/admin/Labs/AdminLabNewPage/AdminLabNewPage'))
);
const AdminLabPage = Loadable(
  lazyRetry(() => import('pages/admin/Labs/AdminLabPage/AdminLabPage'))
);
const AdminTradesPage = Loadable(
  lazyRetry(() => import('pages/admin/Trades/AdminTradesPage/AdminTradesPage'))
);
const AdminTradePage = Loadable(
  lazyRetry(() => import('pages/admin/Trades/AdminTradePage/AdminTradePage'))
);
const AdminTradesInstancesPage = Loadable(
  lazyRetry(
    () => import('pages/admin/TradesInstances/AdminTradesInstancesPage/AdminTradesInstancesPage')
  )
);
const AdminTradeInstancePage = Loadable(
  lazyRetry(
    () => import('pages/admin/TradesInstances/AdminTradeInstancePage/AdminTradeInstancePage')
  )
);
const AdminPharmaciesMergePage = Loadable(
  lazyRetry(() => import('pages/admin/PharmaciesMerge/AdminPharmaciesMergePage'))
);
const AdminDatamatrixGeneratorPage = Loadable(
  lazyRetry(() => import('pages/admin/DatamatrixGenerator/AdminDatamatrixGeneratorPage'))
);
const AdminIngestionsHubPage = Loadable(
  lazyRetry(() => import('pages/admin/IngestionsHub/AdminIngestionsHubPage/AdminIngestionsHubPage'))
);
const AdminIngestionHubNewPage = Loadable(
  lazyRetry(
    () => import('pages/admin/IngestionsHub/AdminIngestionsHubNewPage/AdminIngestionHubNewPage')
  )
);
const AdminMarketingToolsPage = Loadable(
  lazyRetry(
    () => import('pages/admin/MarketingTools/AdminMarketingToolsPage/AdminMarketingToolsPage')
  )
);
const AdminMarketingToolNewPage = Loadable(
  lazyRetry(() => import('pages/admin/MarketingTools/AdminMarketingToolNewPage'))
);
const AdminMarketingToolPage = Loadable(
  lazyRetry(
    () => import('pages/admin/MarketingTools/AdminMarketingToolPage/AdminMarketingToolPage')
  )
);
const AdminUsersPage = Loadable(
  lazyRetry(() => import('pages/admin/Users/AdminUsersPage/AdminUsersPage'))
);
const AdminUserPage = Loadable(
  lazyRetry(() => import('pages/admin/Users/AdminUserPage/AdminUserPage'))
);
const AdminUserNewPage = Loadable(lazyRetry(() => import('pages/admin/Users/AdminUserNewPage')));
const AdminUserEditPage = Loadable(lazyRetry(() => import('pages/admin/Users/AdminUserEditPage')));
const AdminNewsfeedPostsPage = Loadable(
  lazyRetry(() => import('pages/admin/NewsfeedPosts/AdminNewsfeedPostsPage/AdminNewsfeedPostsPage'))
);
const AdminNewsfeedPostPage = Loadable(
  lazyRetry(() => import('pages/admin/NewsfeedPosts/AdminNewsfeedPostPage/AdminNewsfeedPostPage'))
);
const AdminNewsfeedPostNewPage = Loadable(
  lazyRetry(() => import('pages/admin/NewsfeedPosts/AdminNewsfeedPostNewPage'))
);
const AdminNewsfeedPostEditPage = Loadable(
  lazyRetry(() => import('pages/admin/NewsfeedPosts/AdminNewsfeedPostEditPage'))
);
const AdminClaimRelaunchPlansPage = Loadable(
  lazyRetry(
    () =>
      import(
        'pages/admin/ClaimRelaunchPlans/AdminClaimRelaunchPlansPage/AdminClaimRelaunchPlansPage'
      )
  )
);
const AdminClaimRelaunchPlanNewPage = Loadable(
  lazyRetry(() => import('pages/admin/ClaimRelaunchPlans/AdminClaimRelaunchPlanNewPage'))
);
const AdminClaimRelaunchPlanEditPage = Loadable(
  lazyRetry(() => import('pages/admin/ClaimRelaunchPlans/AdminClaimRelaunchPlanEditPage'))
);
const AdminGroupsLabsPage = Loadable(
  lazyRetry(() => import('pages/admin/GroupsLabs/AdminGroupsLabsPage/AdminGroupsLabsPage'))
);
const AdminCheckNeedingPharmaciesPage = Loadable(
  lazyRetry(
    () =>
      import(
        'pages/admin/CheckNeedingPharmacies/AdminCheckNeedingPharmaciesPage/AdminCheckNeedingPharmaciesPage'
      )
  )
);
const AdminReferentialPharmaciesPage = Loadable(
  lazyRetry(
    () =>
      import(
        'pages/admin/ReferentialPharmacies/AdminReferentialPharmaciesPage/AdminReferentialPharmaciesPage'
      )
  )
);
const SendResetPasswordEmail = Loadable(
  lazyRetry(() => import('pages/shared/ResetPassword/SendEmail/SendEmail'))
);
const EditPassword = Loadable(
  lazyRetry(() => import('pages/shared/ResetPassword/EditPassword/EditPassword'))
);
const PharmacyStripePage = Loadable(
  lazyRetry(() => import('pages/pharmacy/Stripe/PharmacyStripePage'))
);
const StripeStatePage = Loadable(lazyRetry(() => import('pages/shared/StripeStatePage')));
const PharmacyStripeBillingFormPage = Loadable(
  lazyRetry(() => import('pages/pharmacy/Stripe/PharmacyStripeBillingFormPage'))
);
const PharmacyPanelistConnectionPage = Loadable(
  lazyRetry(() => import('pages/pharmacy/PanelistConnection/PharmacyPanelistConnectionPage'))
);
