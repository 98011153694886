export enum AdminPaths {
  Home = '/admin/home',
  Impersonate = '/admin/tools/impersonate',
  PharmaciesMerge = '/admin/tools/pharmacies/merge',
  Datamatrix = '/admin/tools/datamatrix',
  SelloutPanelistGenerator = '/admin/tools/sellout_panelist_generator',
  IngestionsHub = '/admin/tools/ingestions',
  NewIngestion = '/admin/tools/ingestions/new-ingestion',
  InboundMails = '/admin/inbound-mails',
  Orders = '/admin/orders',
  Order = '/admin/orders/:id',
  OrderEdit = '/admin/orders/:id/edit',
  OrderNew = '/admin/orders/new',
  LabTrades = '/admin/lab_trades',
  ExpiredProductsRequests = '/admin/expired_products_requests',
  ExpiredProductsRequest = '/admin/expired_products_requests/:id',
  Claims = '/admin/invoices',
  Claim = '/admin/invoices/:id',
  Trades = '/admin/trades',
  Trade = '/admin/trades/:id',
  TradesInstances = '/admin/trades/instances',
  TradeInstance = '/admin/trades/instances/:id',
  Labs = '/admin/labs',
  LabNew = '/admin/labs/new',
  Lab = '/admin/labs/:id',
  MarketingTools = '/admin/tools/marketing_tools',
  MarketingToolNew = '/admin/tools/marketing_tools/new',
  MarketingTool = '/admin/tools/marketing_tools/:id',
  Groups = '/admin/groups',
  GroupNew = '/admin/groups/new',
  Group = '/admin/groups/:id',
  Pharmacies = '/admin/pharmacies',
  PharmacyNew = '/admin/pharmacies/new',
  Pharmacy = '/admin/pharmacies/:id',
  NewsfeedPosts = '/admin/newsfeed_posts',
  NewsfeedPost = '/admin/newsfeed_posts/:id',
  NewsfeedPostNew = '/admin/newsfeed_posts/new',
  NewsfeedPostEdit = '/admin/newsfeed_posts/:id/edit',
  ClaimRelaunchPlans = '/admin/claim_relaunch_plans',
  ClaimRelaunchPlanNew = '/admin/claim_relaunch_plans/new',
  ClaimRelaunchPlanEdit = '/admin/claim_relaunch_plans/:id/edit',
  Users = '/admin/users',
  User = '/admin/users/:id',
  UserNew = '/admin/users/new',
  UserEdit = '/admin/users/:id/edit',
  GroupsLabs = '/admin/tools/groups_labs',
  CheckNeedingPharmacies = '/admin/tools/check_needing_pharmacies',
  ReferentialPharmacies = '/admin/tools/referential_pharmacies',
}
