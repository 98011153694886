import { Box, Button } from '@mui/material';
import { useCreateMutation } from 'api';
import { NumberTextField } from 'components/NumberTextField';
import PageContainer from 'components/PageContainer';
import Select from 'components/Select';
import { useTranslation } from 'locales/i18n';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { ExpectedResult, expectedResultValues } from 'types/api/admin/trades_instances';

export const AdminSelloutPanelistGeneratorPage = () => {
  const { t } = useTranslation();
  const [tradesInstanceId, setTradesInstanceId] = useState('');
  const [expectedResult, setExpectedResult] = useState('mark_as_done' as ExpectedResult);

  const createFakePanelistRequestMutation = useCreateMutation(
    'admin/fake_sellout_panelist_requests'
  );

  const createFakePanelistRequest = () => {
    return createFakePanelistRequestMutation
      .mutateAsync({
        trades_instance_id: +tradesInstanceId,
        expected_result: expectedResult,
      })
      .then((response) => {
        if (response.error_message) {
          toast.error(t(`selloutPanelistGenerator.errorMessages.${response.error_message}`));
        } else if (response.success) {
          toast.success(t('shared.changesDone'));
        }
      });
  };

  const expectedResultOptions = expectedResultValues.map((expected_result_value) => ({
    value: expected_result_value,
    title: t(`selloutPanelistGenerator.expectedResultOptions.${expected_result_value}`),
  }));

  return (
    <PageContainer pageTitle={t('shared.selloutPanelistGenerator')} showGoBackLink={false}>
      <Box
        display="flex"
        sx={{ mb: 2, flexDirection: 'column', gap: '20px', alignItems: 'center' }}
      >
        <NumberTextField
          variant="outlined"
          sx={{ width: '100%' }}
          placeholder={t('selloutPanelistGenerator.instanceId')}
          value={tradesInstanceId}
          onChange={(event) => setTradesInstanceId(event.target.value)}
          required
        />
        <Select
          label={t('selloutPanelistGenerator.expectedResult')}
          value={expectedResult}
          onChange={(event) => setExpectedResult(event.target.value as ExpectedResult)}
          options={expectedResultOptions}
          required
        />
        <Button
          onClick={() => createFakePanelistRequest()}
          variant="outlined"
          sx={{ ml: 2, width: 'fit-content' }}
          disabled={isEmpty(tradesInstanceId) || isEmpty(expectedResult)}
        >
          {t('selloutPanelistGenerator.update')}
        </Button>
      </Box>
    </PageContainer>
  );
};

export default AdminSelloutPanelistGeneratorPage;
