import { yupResolver } from '@hookform/resolvers/yup';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Card, Stack, Typography, useTheme } from '@mui/material';
import { useIndexQuery, useUpdatePharmacyMutation } from 'api';
import { CenteredSpinner } from 'components/CenteredSpinner';
import { Banner } from 'components/banner/banner';
import { FormProvider, SelectField, TextField } from 'components/hook-form';
import { CheckboxWithLabel } from 'components/hook-form/CheckboxWithLabel';
import { StyledLink } from 'components/link/styles';
import { BasicModal } from 'components/modals/BasicModal';
import { useCurrentPharmacyOrThrow } from 'hooks/slices';
import { useTranslation } from 'locales/i18n';
import { startCase } from 'lodash';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { SetPanelistConnectionParams } from 'types/api/pharmacies/panelist_connection';
import { Panelist } from 'types/models/pharmacy_lgo_settings';
import { LGO_APPLICATION_DATE, PANELISTS } from 'utils/lgoUtils';
import { LGO_LINKS } from 'utils/linkUtils';
import * as Yup from 'yup';

type ConnectLgoFormValues = {
  panelist: Panelist;
  areConditionsAccepted: boolean;
  cip: string;
};

type Props = {
  handleOnSet: (panelistConnectionParams: SetPanelistConnectionParams) => void;
  disableConnectButton: boolean;
  isLoading: boolean;
};

export const ConnectPanelistCard = ({ handleOnSet, disableConnectButton, isLoading }: Props) => {
  const theme = useTheme();
  const pharmacy = useCurrentPharmacyOrThrow();
  const { t } = useTranslation();

  const [isConfirmCIPModalOpen, setIsConfirmCIPModalOpen] = useState(false);

  const updatePharmacy = useUpdatePharmacyMutation();

  const pharmacyGroupsQuery = useIndexQuery('groups_pharmacies', undefined, { enabled: true });
  const defaultGroupPharmacy = pharmacyGroupsQuery.data?.groups_pharmacies.find(
    (group) => group.default
  );
  const suggestedPanelist = defaultGroupPharmacy?.group_setting.suggested_panelist;

  const defaultFormValues: ConnectLgoFormValues = {
    panelist: suggestedPanelist || 'ospharm',
    areConditionsAccepted: false,
    cip: pharmacy.cip || '',
  };

  const doSubmit = () => {
    const pharmacyPanelistConnectionParams = {
      panelist: connectLgoFormValues.panelist,
      accepted_text: textThatMustBeAccepted,
      application_date: LGO_APPLICATION_DATE.format(),
    };

    handleOnSet({
      pharmacy_lgo_setting: pharmacyPanelistConnectionParams,
    });
  };

  const ConnectLgoFormSchema = Yup.object().shape({
    panelist: Yup.string().required(),
    areConditionsAccepted: Yup.boolean().test('areConditionsAccepted', (value) => value === true),
  });

  const CheckCipFormSchema = Yup.object().shape({
    cip: Yup.string()
      .length(7, t('account.pharmacyForm.errors.cipLength'))
      .required()
      .matches(/^\d+$/, t('account.pharmacyForm.errors.cipNumber')),
  });

  const methods = useForm<ConnectLgoFormValues>({
    resolver: yupResolver(ConnectLgoFormSchema.concat(CheckCipFormSchema)),
    defaultValues: defaultFormValues,
  });

  const { handleSubmit, watch, reset } = methods;

  const connectLgoFormValues = watch();

  useEffect(() => {
    reset(defaultFormValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, defaultFormValues.panelist]);

  const onSubmit = () => {
    updatePharmacy
      .mutateAsync({
        pharmacy: { id: pharmacy.id, cip: connectLgoFormValues.cip },
      })
      .then(doSubmit)
      .catch((error) => {
        const errorMessage = error?.response?.data?.error;

        if (errorMessage === 'cip_already_taken') {
          toast.error(t('pharmacyLgoConnection.errors.cip_already_taken'));
        } else {
          throw error;
        }
      });
  };

  const panelistOptions = PANELISTS.map((panelist) => ({
    value: panelist,
    title: panelist.toUpperCase(),
  }));

  const textThatMustBeAccepted =
    t('pharmacyLgoConnection.textThatMustBeAccepted.start', {
      panelist: startCase(connectLgoFormValues.panelist),
      applicationDate: LGO_APPLICATION_DATE.format('LL'),
    }) +
    ' ' +
    t('pharmacyLgoConnection.textThatMustBeAccepted.faksPrivacyPolicy') +
    (connectLgoFormValues.panelist === 'santestat'
      ? ` ${t('pharmacyLgoConnection.textThatMustBeAccepted.santestatCguStart')} ` +
        t('pharmacyLgoConnection.textThatMustBeAccepted.santestatCgu')
      : '');

  if (pharmacyGroupsQuery.isLoading) return <CenteredSpinner />;

  return (
    <FormProvider methods={methods}>
      <Card sx={{ p: '25px' }}>
        <Typography variant="body2" fontWeight={700} color={theme.palette.grey[500]}>
          {t('pharmacyLgoConnection.chooseYourPanelist').toUpperCase()}
        </Typography>
        {suggestedPanelist && (
          <Banner
            icon={<InfoIcon fontSize="medium" />}
            title={t('pharmacyLgoConnection.suggestedPanelist', {
              panelist: suggestedPanelist.toUpperCase(),
            })}
            sx={{ mt: 2 }}
          />
        )}
        <Stack gap={2} marginTop={3}>
          <SelectField
            name="panelist"
            options={panelistOptions}
            label={t('pharmacyLgoConnection.selectYourPanelist')}
            sx={{ maxWidth: { lg: '20%' } }}
          />

          {connectLgoFormValues.panelist !== 'santestat' && (
            <Typography variant="body2" fontStyle="italic">
              {t('pharmacyLgoConnection.noPanelistAccount')}{' '}
              <StyledLink
                href={LGO_LINKS.ONBOARDINGS[connectLgoFormValues.panelist]}
                target="_blank"
              >
                {t('pharmacyLgoConnection.createPanelistAccount', {
                  panelist: startCase(connectLgoFormValues.panelist),
                })}
              </StyledLink>
            </Typography>
          )}

          <Banner
            icon={<CheckCircleIcon fontSize="medium" sx={{ marginRight: 1 }} />}
            title={
              <>
                <Box>{t('pharmacyLgoConnection.faksDataPolicy')}</Box>
                <StyledLink href={LGO_LINKS.INTERCOM} target="_blank">
                  {t('pharmacyLgoConnection.dataPolicyKnowMore')}
                </StyledLink>
              </>
            }
            color="success"
            sx={{ maxWidth: { lg: '40%' } }}
          />

          <CheckboxWithLabel
            name="areConditionsAccepted"
            label={
              <Typography fontWeight={600}>
                {t('pharmacyLgoConnection.textThatMustBeAccepted.start', {
                  panelist: startCase(connectLgoFormValues.panelist),
                  applicationDate: LGO_APPLICATION_DATE.format('LL'),
                })}{' '}
                <StyledLink href={LGO_LINKS.DATA_POLICY} target="_blank">
                  {t('pharmacyLgoConnection.textThatMustBeAccepted.faksPrivacyPolicy')}
                </StyledLink>
                {connectLgoFormValues.panelist === 'santestat' && (
                  <>
                    {` ${t('pharmacyLgoConnection.textThatMustBeAccepted.santestatCguStart')} `}
                    <StyledLink
                      href="https://storage.googleapis.com/faks-public-assets/CGU_SANTESTAT_0723.pdf"
                      target="_blank"
                    >
                      {t('pharmacyLgoConnection.textThatMustBeAccepted.santestatCgu')}
                    </StyledLink>
                  </>
                )}
              </Typography>
            }
          />

          <Box display="flex" justifyContent="flex-end">
            <Button
              variant="contained"
              disabled={disableConnectButton}
              onClick={() =>
                ConnectLgoFormSchema.validate(connectLgoFormValues)
                  .then(() => setIsConfirmCIPModalOpen(true))
                  .catch((error) => {
                    if (error.message.includes('areConditionsAccepted')) {
                      toast.error(t('pharmacyLgoConnection.errors.textMustBeAccepted'));
                    } else {
                      throw error;
                    }
                  })
              }
            >
              {t('shared.confirm')}
            </Button>
          </Box>
        </Stack>
      </Card>

      <BasicModal
        open={isConfirmCIPModalOpen}
        onClose={() => setIsConfirmCIPModalOpen(false)}
        title={t('pharmacyLgoConnection.verifyCip')}
      >
        <>
          <Typography my={2}>{t('pharmacyLgoConnection.checkThatYourCipIsCorrect')}</Typography>
          <TextField
            name="cip"
            label={t('account.pharmacyForm.cip')}
            placeholder={pharmacy.cip || ''}
          />
          <Box display="flex" justifyContent="flex-end">
            <LoadingButton
              onClick={handleSubmit(onSubmit)}
              loading={updatePharmacy.isLoading || isLoading}
              variant="contained"
              sx={{ mt: 2 }}
            >
              {t('shared.confirm')}
            </LoadingButton>
          </Box>
        </>
      </BasicModal>
    </FormProvider>
  );
};
